<template>
  <div class="join">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="baseBox">
      <div class="joinBox errorpage">
        <h1 style="text-align: center;"><i class="fas fa-exclamation-triangle"></i>Error 404</h1>
        <h3 style="text-align: center; color: #969696;">
          죄송합니다. 현재 찾으시는 페이지를 찾을 수 없습니다.<br />새로고침하거나
          이전 페이지로 이동 후 다른 페이지를 클릭해주세요.
        </h3>
        <div class="buttonWrap">
          <router-link to="/"
            ><button class="point large" @click="$router.go(-1)">
              이전페이지로 이동
            </button></router-link
          >
          <router-link :to="getUrl()"
          >
            <button class="large" style="border: 1px solid #ddd;">홈으로 이동</button>
          </router-link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";

export default {
  components: { Footer },
  mounted() {
  },
  methods : {
    getUrl(){
      var accountId = this.$route.params.accountId;
      //var userType = localStorage.getItem("userType");
      var result = "";
      if(this.$store.state.store.isAdmin == true) {
        result = `/${accountId}/dashboard/overallAdmin`;
      } else{
        result = `/${accountId}/eventControl/liveListControlUser`;
      }
      return result
    }
  },
};
</script>
